import React, {Fragment, useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'
import {powerpointService} from "./../../services/PowerpointService";
import {closeExportPowerpointModal, openViewImageModal} from "./../../redux/toolkit/features/settings_state";
import {coverImage, growthImage, roadmapsImage, sprintsImage, innovationImage, peopleImage, stakeholderImage} from "./../../redux/toolkit/features/tenants";
import Spinner from "./../common/Spinner";



function ExportPowerpointModal() {
    const tenant = Object.values(useSelector((state) =>  state.tenants.entities))[0];
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const isExportPowerpointModal = useSelector((state) => state.settings_state.isExportPowerpointModal);
    const dispatch = useDispatch();
    const [growth, setGrowth] = useState(true)
    const [values, setValues] = useState(true)
    const [timeline, setTimeline] = useState(true)
    const [facts, setFacts] = useState(true)
    const [success, setSuccess] = useState(true)
    const [roadmaps, setRoadmaps] = useState(true)
    const [sprints, setSprints] = useState(true)
    const [innovation, setInnovation] = useState(true)
    const [people, setPeople] = useState(true)
    const [stakeholders, setStakeholders] = useState(true)
    const tabs = ['Sections', 'Images'];
    const [activetab, setActivetab] = useState(tabs[0]);

     let modalClass = "modal";
      if (isExportPowerpointModal) {
          modalClass += " is-active";
      }

    var modaltitle='CREATE POWERPOINT';

    async function changeImage(filename, section) {

        switch(section) {
            case 'Cover':
                await dispatch(coverImage({id:tenant.pk,file:filename[0]}))
                .unwrap()
              return null
            case 'Growth':
                await dispatch(growthImage({id:tenant.pk,file:filename[0]}))
                .unwrap()
            return null
            case 'Roadmaps':
                await dispatch(roadmapsImage({id:tenant.pk,file:filename[0]}))
                .unwrap()
            return null
            case 'Sprint':
                await dispatch(sprintsImage({id:tenant.pk,file:filename[0]}))
                .unwrap()
            return null
            case 'Innovation':
                await dispatch(innovationImage({id:tenant.pk,file:filename[0]}))
                .unwrap()
            return null
            case 'People':
                await dispatch(peopleImage({id:tenant.pk,file:filename[0]}))
                .unwrap()
            return null
            case 'Stakeholder':
                await dispatch(stakeholderImage({id:tenant.pk,file:filename[0]}))
                .unwrap()
            return null

            default:
              return null
          }
        }

        function formtabs() {
            return (
                <div className="tabs" style={{backgroundColor:'rgb(236, 236, 236)'}}>
                    <ul className="ml-2">
                        {tabs.map(tab => (
                            <li className ={activetab === tab ? "is-active" : ""}
                                key={"tab_"+tab}
                                onClick={() => setActivetab(tab)}
                                style={{color:"grey"}}
                            >
                            <a>{tab}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            )
        }


 
    function renderForm() {
        return (
            <Fragment>
                <div className="ml-5">
                    <p>Sections to include:</p>
                <div className="mt-2">
                    <label className="checkbox">
                    <input type="checkbox" checked={growth} onChange={() => growth?setGrowth(false):setGrowth(true)}/>
                    <span className="ml-3">Growth Story</span>
                    </label>
                </div>

                <div className="mt-1">
                    <label className="checkbox">
                    <input type="checkbox" checked={values} onChange={() => values?setValues(false):setValues(true)}/>
                    <span className="ml-3">Company Values</span>
                    </label>
                </div>

                <div className="mt-1">
                    <label className="checkbox">
                    <input type="checkbox" checked={timeline} onChange={() => timeline?setTimeline(false):setTimeline(true)}/>
                    <span className="ml-3">Company Timeline</span>
                    </label>
                </div>

                <div className="mt-1">
                    <label className="checkbox">
                    <input type="checkbox" checked={facts} onChange={() => facts?setFacts(false):setFacts(true)}/>
                    <span className="ml-3">Company Facts</span>
                    </label>
                </div>

                <div className="mt-1">
                    <label className="checkbox">
                    <input type="checkbox" checked={success} onChange={() => success?setSuccess(false):setSuccess(true)}/>
                    <span className="ml-3">Success Visions</span>
                    </label>
                </div>

                <div className="mt-1">
                    <label className="checkbox">
                    <input type="checkbox" checked={roadmaps} onChange={() => roadmaps?setRoadmaps(false):setRoadmaps(true)}/>
                    <span className="ml-3">Roadmaps</span>
                    </label>
                </div>

                <div className="mt-1">
                    <label className="checkbox">
                    <input type="checkbox" checked={sprints} onChange={() => sprints?setSprints(false):setSprints(true)}/>
                    <span className="ml-3">Sprints</span>
                    </label>
                </div>

                <div className="mt-1">
                    <label className="checkbox">
                    <input type="checkbox" checked={innovation} onChange={() => innovation?setInnovation(false):setInnovation(true)}/>
                    <span className="ml-3">Innovation</span>
                    </label>
                </div>

                <div className="mt-1">
                    <label className="checkbox">
                    <input type="checkbox" checked={people} onChange={() => people?setPeople(false):setPeople(true)}/>
                    <span className="ml-3">People</span>
                    </label>
                </div>

                <div className="mt-1">
                    <label className="checkbox">
                    <input type="checkbox" checked={stakeholders} onChange={() => stakeholders?setStakeholders(false):setStakeholders(true)}/>
                    <span className="ml-3">Stakeholders</span>
                    </label>
                </div>
            </div>

            </Fragment>
        );
    }

    function renderImages() {
        return (
            <div className="ml-3">
                <p className="ml-3 mb-0">Customize report images:</p><hr className="ml-2 mt-2 mb-1 mr-5"/>

            <table className="table is-fullwidth is-borderless">
                <tr>
                    <td className="pt-3" style={{borderBottom:'None', fontSize:'0.92em', fontWeight:'400'}}>Cover Image</td>
                    <td className="pt-3" style={{fontSize:'0.9em', color:backgroundcolor, cursor:'pointer',borderBottom:'None'}} onClick={()=> dispatch(openViewImageModal({imagetype:'Cover'}))}>View</td>
                    <td style={{borderBottom:'None'}}>
                        <button className="button is-small ml-3" style={{borderColor:backgroundcolor, borderRadius:'5px', color:backgroundcolor}}>
                            <input 
                                    className="file-input" 
                                    type="file" 
                                    name="image"
                                    accept="image/gif, image/jpeg, image/png"
                                    onChange={(e) => changeImage(e.target.files, 'Cover')}
                                    />
                            Change
                        </button>
                    </td>
                </tr>
                <tr>
                <td className="pt-3" style={{borderBottom:'None', fontSize:'0.92em', fontWeight:'400'}}>Story Image</td>
                    <td className="pt-3" style={{fontSize:'0.9em', color:backgroundcolor, cursor:'pointer',borderBottom:'None'}} onClick={()=> dispatch(openViewImageModal({imagetype:'Growth'}))}>View</td>
                    <td style={{borderBottom:'None'}}>
                        <button className="button is-small ml-3" style={{borderColor:backgroundcolor, borderRadius:'5px', color:backgroundcolor}}>
                            <input 
                                    className="file-input" 
                                    type="file" 
                                    name="image"
                                    accept="image/gif, image/jpeg, image/png"
                                    onChange={(e) => changeImage(e.target.files, 'Growth')}
                                    />
                            Change
                        </button>
                    </td>
                </tr>
                <tr>
                <td className="pt-3" style={{borderBottom:'None', fontSize:'0.92em', fontWeight:'400'}}>Roadmaps Section</td>
                    <td className="pt-3" style={{fontSize:'0.9em', color:backgroundcolor, cursor:'pointer',borderBottom:'None'}} onClick={()=> dispatch(openViewImageModal({imagetype:'Roadmaps'}))}>View</td>
                    <td style={{borderBottom:'None'}}>
                        <button className="button is-small ml-3" style={{borderColor:backgroundcolor, borderRadius:'5px', color:backgroundcolor}}>
                            <input 
                                    className="file-input" 
                                    type="file" 
                                    name="image"
                                    accept="image/gif, image/jpeg, image/png"
                                    onChange={(e) => changeImage(e.target.files, 'Roadmaps')}
                                    />
                            Change
                        </button>
                    </td>                  
                </tr>
                <tr>
                <td className="pt-3" style={{borderBottom:'None', fontSize:'0.92em', fontWeight:'400'}}>Sprint Section</td>
                    <td className="pt-3" style={{fontSize:'0.9em', color:backgroundcolor, cursor:'pointer',borderBottom:'None'}} onClick={()=> dispatch(openViewImageModal({imagetype:'Sprints'}))}>View</td>
                    <td style={{borderBottom:'None'}}>
                        <button className="button is-small ml-3" style={{borderColor:backgroundcolor, borderRadius:'5px', color:backgroundcolor}}>
                            <input 
                                    className="file-input" 
                                    type="file" 
                                    name="image"
                                    accept="image/gif, image/jpeg, image/png"
                                    onChange={(e) => changeImage(e.target.files, 'Sprint')}
                                    />
                            Change
                        </button>
                    </td>
                </tr>
                <tr>
                <td className="pt-3" style={{borderBottom:'None', fontSize:'0.92em', fontWeight:'400'}}>Innovation Section</td>
                    <td className="pt-3" style={{fontSize:'0.9em', color:backgroundcolor, cursor:'pointer',borderBottom:'None'}} onClick={()=> dispatch(openViewImageModal({imagetype:'Innovation'}))}>View</td>
                    <td style={{borderBottom:'None'}}>
                        <button className="button is-small ml-3" style={{borderColor:backgroundcolor, borderRadius:'5px', color:backgroundcolor}}>
                            <input 
                                    className="file-input" 
                                    type="file" 
                                    name="image"
                                    accept="image/gif, image/jpeg, image/png"
                                    onChange={(e) => changeImage(e.target.files, 'Innovation')}
                                    />
                            Change
                        </button>
                    </td>
                </tr>
                <tr>
                <td className="pt-3" style={{borderBottom:'None', fontSize:'0.92em', fontWeight:'400'}}>People Section</td>
                    <td className="pt-3" style={{fontSize:'0.9em', color:backgroundcolor, cursor:'pointer',borderBottom:'None'}} onClick={()=> dispatch(openViewImageModal({imagetype:'People'}))}>View</td>
                    <td style={{borderBottom:'None'}}>
                        <button className="button is-small ml-3" style={{borderColor:backgroundcolor, borderRadius:'5px', color:backgroundcolor}}>
                            <input 
                                    className="file-input" 
                                    type="file" 
                                    name="image"
                                    accept="image/gif, image/jpeg, image/png"
                                    onChange={(e) => changeImage(e.target.files, 'People')}
                                    />
                            Change
                        </button>
                    </td>
                </tr>
                <tr>
                <td className="pt-3" style={{borderBottom:'None', fontSize:'0.92em', fontWeight:'400'}}>Stakeholder Section</td>
                    <td className="pt-3" style={{fontSize:'0.9em', color:backgroundcolor, cursor:'pointer',borderBottom:'None'}} onClick={()=> dispatch(openViewImageModal({imagetype:'Stakeholder'}))}>View</td>
                    <td style={{borderBottom:'None'}}>
                        <button className="button is-small ml-3" style={{borderColor:backgroundcolor, borderRadius:'5px', color:backgroundcolor}}>
                            <input 
                                    className="file-input" 
                                    type="file" 
                                    name="image"
                                    accept="image/gif, image/jpeg, image/png"
                                    onChange={(e) => changeImage(e.target.files, 'Stakeholder')}
                                    />
                            Change
                        </button>
                    </td>
                </tr>
            </table>
            </div>
        )
    }

    return (
        <Fragment>
                                    
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard" style={{width:'400px', height: '550px'}}>
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>{modaltitle}</p>
                        <button className="delete" aria-label="close" onClick={() => dispatch(closeExportPowerpointModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body m-0 p-0" key="modalBody">
                        {formtabs()}
                        {activetab==='Sections' && renderForm()}
                        {activetab==='Images' && renderImages()}
                    </section>
                    <footer className="modal-card-foot is-flex" key="modalFooter" style={{height:"60px"}}>

                        <button className="button is-small" style={{borderRadius:"4px", color:textcolor, backgroundColor:backgroundcolor}} onClick={async () => {
                            await powerpointService.getPowerpoint({growth:growth, values: values, facts:facts,timeline:timeline,success:success, roadmaps:roadmaps, sprints: sprints, innovation:innovation,people:people, stakeholders:stakeholders})
                             dispatch(closeExportPowerpointModal())
                            }}>
                            Export   
                        </button>

                        <button className="button is-small" key="cancel" style={{borderRadius:"4px"}} 
                            onClick={() => {
                                dispatch(closeExportPowerpointModal());
                                }}>
                            Cancel
                        </button>
                    </footer>
                </div>
            </div>
        </Fragment>
    );
}

export default ExportPowerpointModal;
