import React, {Fragment, useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'
import {closeViewImageModal} from "./../../redux/toolkit/features/settings_state";
import {fetchAllTenants} from "./../../redux/toolkit/features/tenants";

function ViewImageModal() {
    const tenant = Object.values(useSelector((state) =>  state.tenants.entities))[0];
    const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
    const textcolor = useSelector((state) => state.colors.textcolor)
    const isViewImageModal = useSelector((state) => state.settings_state.isViewImageModal);
    const imagetype = useSelector((state) => state.settings_state.imagetype);
    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(fetchAllTenants())

    },[]);

    let modalClass = "modal";
    if (isViewImageModal) {
        modalClass += " is-active";
    }

    var modaltitle=""
    var imageURL = null


    if (imagetype==='Cover') {
        modaltitle = 'Cover Image (16:9 Ratio)'
        imageURL = tenant.coverimage
    }
    if (imagetype==='Growth') {
        modaltitle = 'Growth Strategy Image (16:9 Ratio)'
        imageURL = tenant.growthimage
        console.log("At Growth", imageURL, modaltitle, tenant)
    }
    if (imagetype==='Roadmaps') {
        modaltitle = 'Roadmap Section Image (16:9 Ratio)'
        imageURL = tenant.roadmapsimage
        if (imageURL===null) {
            imageURL = 'http://localhost:8000/media/images/PPTXImages/roadmap.png'
        }
    }
    if (imagetype==='Sprints') {
        modaltitle = 'Sprint Section Image (16:9 Ratio)'
        imageURL = tenant.sprintsimage
    }
    if (imagetype==='Innovation') {
        modaltitle = 'Innovation Section Image (16:9 Ratio)'
        imageURL = tenant.innovationimage
    }
    if (imagetype==='People') {
        modaltitle = 'People Section Image (16:9 Ratio)'
        imageURL = tenant.peopleimage
    }
    if (imagetype==='Stakeholder') {
        modaltitle = 'Stakeholder Section Image (16:9 Ratio)'
        imageURL = tenant.stakeholderimage
    }

    let backgroundStyle = {backgroundColor: 'blue', userSelect: "none"};
    if (imageURL) {
        backgroundStyle = {
            backgroundImage: 'url(' + imageURL + ')',
            backgroundSize: "100% 100%",
            userSelect: "none",
            backgroundPosition: "center"
        };
    }

    console.log("Image tyoe", imagetype, imageURL, tenant.growthimage, tenant)
    return (
        <Fragment>
            <div className={modalClass} key="categoryModal" style={{userSelect: "none"}}>
                <div className="modal-background" key="modalBackground">
                    {" "}
                </div>
                <div className="modal-card" key="modalCard" style={{width:'800px', height: '500px'}}>
                    <header className="modal-card-head" key="modalHeader" style={{height:"50px", backgroundColor: backgroundcolor}}>
                        <p className="modal-card-title" style={{fontSize:"17px", color: textcolor}}>{modaltitle}</p>
                        <button className="delete" aria-label="close" onClick={() => dispatch(closeViewImageModal())}>
                            {" "}
                        </button>
                    </header>
                    <section className="modal-card-body m-0 p-0" key="modalBody" style={backgroundStyle}>

                    </section>
                </div>
            </div>
        </Fragment>
    );
}

export default ViewImageModal;
