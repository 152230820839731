import axios from "axios";
import { authConfig } from "../../../services/helpers";
import apiUtils from "../utils/apiUtils";
import noLogo from "../../../components/common/images/noLogo.png";

export const fetchAllTenants = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/tenants`,
    authConfig()
  );
  return response.data.results;
};

export const fetchTenants = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/tenants/${id}`,
    authConfig()
  );
  return response.data;
};

export const createTenants = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/tenants`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateTenants = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/tenants/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteTenants = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/tenants/${id}`,
    authConfig()
  );
  return response.data;
};

export const uploadLogo = async (data) => {
  const url = `${apiUtils.getBaseURL()}/tenants/` + (data.id) + "/upload-logo";
  const formData = new FormData();
  formData.append("file", data.file);
  const config = authConfig();
  config.headers["content-type"] = "multipart/form-data";
  const response = await axios.put(url, formData, config);
  return response.data;
};

export const logoUrl = (tenant) => {
  if (tenant.image) {
    return tenant.image.startsWith("http")
        ? tenant.image
        :  `${apiUtils.getBaseURL()}` + tenant.image;
  }
  return noLogo;
}

export const coverImage = async (data) => {
  const url = `${apiUtils.getBaseURL()}/tenants/` + (data.id) + "/upload-coverimage";
  const formData = new FormData();
  formData.append("file", data.file);
  const config = authConfig();
  config.headers["content-type"] = "multipart/form-data";
  const response = await axios.put(url, formData, config);
  return response.data;
};

export const coverImageUrl = (tenant) => {
  if (tenant.coverimage) {
    return tenant.coverimage.startsWith("http")
        ? tenant.coverimage
        :  `${apiUtils.getBaseURL()}` + tenant.coverimage;
  }
  return noLogo;
}

export const growthImage = async (data) => {
  const url = `${apiUtils.getBaseURL()}/tenants/` + (data.id) + "/upload-growthimage";
  const formData = new FormData();
  formData.append("file", data.file);
  const config = authConfig();
  config.headers["content-type"] = "multipart/form-data";
  const response = await axios.put(url, formData, config);
  return response.data;
};

export const roadmapsImage = async (data) => {
  const url = `${apiUtils.getBaseURL()}/tenants/` + (data.id) + "/upload-roadmapsimage";
  const formData = new FormData();
  formData.append("file", data.file);
  const config = authConfig();
  config.headers["content-type"] = "multipart/form-data";
  const response = await axios.put(url, formData, config);
  return response.data;
};

export const sprintsImage = async (data) => {
  const url = `${apiUtils.getBaseURL()}/tenants/` + (data.id) + "/upload-sprintsimage";
  const formData = new FormData();
  formData.append("file", data.file);
  const config = authConfig();
  config.headers["content-type"] = "multipart/form-data";
  const response = await axios.put(url, formData, config);
  return response.data;
};

export const innovationImage = async (data) => {
  const url = `${apiUtils.getBaseURL()}/tenants/` + (data.id) + "/upload-innovationimage";
  const formData = new FormData();
  formData.append("file", data.file);
  const config = authConfig();
  config.headers["content-type"] = "multipart/form-data";
  const response = await axios.put(url, formData, config);
  return response.data;
};

export const peopleImage = async (data) => {
  const url = `${apiUtils.getBaseURL()}/tenants/` + (data.id) + "/upload-peopleimage";
  const formData = new FormData();
  formData.append("file", data.file);
  const config = authConfig();
  config.headers["content-type"] = "multipart/form-data";
  const response = await axios.put(url, formData, config);
  return response.data;
};

export const stakeholderImage = async (data) => {
  const url = `${apiUtils.getBaseURL()}/tenants/` + (data.id) + "/upload-stakeholderimage";
  const formData = new FormData();
  formData.append("file", data.file);
  const config = authConfig();
  config.headers["content-type"] = "multipart/form-data";
  const response = await axios.put(url, formData, config);
  return response.data;
};

const tenantsAPI = {
  fetchAllTenants,
  fetchTenants,
  createTenants,
  updateTenants,
  deleteTenants,
  uploadLogo,
  logoUrl,
  coverImage,
  coverImageUrl,
  growthImage,
  roadmapsImage,
  sprintsImage,
  innovationImage,
  peopleImage,
  stakeholderImage
};
export default tenantsAPI;
