
import "regenerator-runtime/runtime.js";
import BaseService from "./BaseService";
import axios from "axios";
import {authConfig} from "./helpers";

class PowerPointService extends BaseService {
    listUrl() {
        return `${this.baseUrl}/organization/powerpoint`;
    }

    async getPowerpoint(data) {
        console.log("Service Data", data)
        const url = this.listUrl();
        // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
        const config = authConfig()
        config.headers['Content-Type'] = 'blob';
        config.method = 'GET';
        config.url = url;
        config.responseType = 'arraybuffer';
        config.params= data
        //config.data= data

        try {
            //const response = await axios(config);
            const response = await axios(config);
            const outputFilename = `PowerPoint_${Date.now()}.pptx`;

            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            throw Error(error);
        }
    }
}

export const powerpointService = new PowerPointService();