import axios from "axios";
import { authConfig } from "../../../../services/helpers";
import apiUtils from "../../utils/apiUtils";

export const fetchAllOrganization = async () => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/organization`,
    authConfig()
  );
  return response.data.results;
};

export const fetchOrganization = async (id) => {
  const response = await axios.get(
    `${apiUtils.getBaseURL()}/organization/${id}`,
    authConfig()
  );
  return response.data;
};

export const createOrganization = async (data) => {
  const response = await axios.post(
    `${apiUtils.getBaseURL()}/organization`,
    data,
    authConfig()
  );
  return response.data;
};

export const updateOrganization = async (data) => {
  const response = await axios.put(
    `${apiUtils.getBaseURL()}/organization/${data.id}`,
    data,
    authConfig()
  );
  return response.data;
};

export const deleteOrganization = async (id) => {
  const response = await axios.delete(
    `${apiUtils.getBaseURL()}/organization/${id}`,
    authConfig()
  );
  return response.data;
};

const organizationAPI = {
  fetchAllOrganization,
  fetchOrganization,
  createOrganization,
  updateOrganization,
  deleteOrganization,
};
export default organizationAPI;
