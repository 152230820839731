import {
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

const settings_stateAdapter = createEntityAdapter();

const initialState = settings_stateAdapter.getInitialState({
  isExportPowerpointModal: false, 
  isViewImageModal: false,
  imagetype: null
});

const settings_stateSlice = createSlice({
  name: "settings_state",
  initialState,
  reducers: {
    openExportPowerpointModal(state) {
      state.isExportPowerpointModal = true;
    },
    closeExportPowerpointModal(state) {
      state.isExportPowerpointModal = false;
    },
    openViewImageModal(state, action) {
      state.isViewImageModal = true;
      state.imagetype = action.payload.imagetype;
    },
    closeViewImageModal(state) {
      state.isViewImageModal = false;
      state.imagetype = null;
    },
  },
});

export const { 
  openExportPowerpointModal,
  closeExportPowerpointModal,
  openViewImageModal,
  closeViewImageModal,
} = settings_stateSlice.actions;

export default settings_stateSlice.reducer;



