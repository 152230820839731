import React, { Fragment, useEffect, useState, createContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./common/css/masterstyles.css";
import { OrgTable } from "./organization/OrgTable";
import { OrgChart } from "./organization/OrgChart";
import { OrgMetrics } from "./organization/PeopleMetrics";
import { Talent } from "./organization/Talent";
import {talentrolepersonService} from "../services/TalentRolePersonService";
import {talentsankeyService} from "../services/TalentSankeyService"
import {tenantsService} from "../services/TenantsService"
import {metricsviewService} from "../services/OrganizationMetricsService";
import { fetchAllRows } from "./../redux/toolkit/features/roadmaprows";
import { fetchAllCells } from "./../redux/toolkit/features/roadmapcells";
import { fetchAllGrowth } from "./../redux/toolkit/features/roadmapgrowth";
import { fetchAllCagr } from "./../redux/toolkit/features/roadmapcagr";
import PeopleModal from "./organization/modals/PeopleModal";
import PeopleModalAddPeople from "./organization/modals/PeopleModalAddPeople";
import PeopleModalAddCompetency from "./organization/modals/PeopleModalAddCompetency";
import PeopleModalAddReporting from "./organization/modals/PeopleModalAddReporting";
import SelectTalentPersonModal from "./organization/modals/SelectTalentPersonModal";
import PeoplesModal from "./organization/modals/PeoplesModal";
import { TalentScoresModal } from "./organization/modals/TalentScoresModal";
import { TalentScoresOverallModal } from "./organization/modals/TalentScoresOverallModal";
import { Roles } from "./organization/Roles";
import { People } from "./organization/People";
import { Competencies } from "./organization/Competencies";
import { Compensation } from "./organization/Compensation";
import Spinner from "./common/Spinner";
import CompetenciesModal from "./../components/organization/modals/CompetenciesModal";
import MetricsModal from "./../components/organization/modals/MetricsModal";
import MetricsModalLink from "./../components/organization/modals/MetricsModalLink";
import ConfirmDeleteBox from "./common/ConfirmDeleteBox";
import { closeDeleteModal }  from "./../redux/toolkit/features/modals/deleteConfirmModal";
import { setMenu } from "../redux/toolkit/features/menu";
import { deleteCompetencies, fetchAllCompetencies } from "./../redux/toolkit/features/competencies";
import {  openRolesModal } from "./../redux/toolkit/features/organization_state";
import { deletePersons, fetchAllPersons } from "./../redux/toolkit/features/organization/persons";
import { fetchAllProductivity, deleteProductivity } from "../redux/toolkit/features/organization/productivity";
import { deleteRolePerson, fetchAllRolePerson } from "../redux/toolkit/features/roleperson";
import { deleteRoles, fetchAllRoles } from "./../redux/toolkit/features/roles";
import { deleteRoleCompetencies, fetchAllRoleCompetencies } from "../redux/toolkit/features/rolecompetencies";
import { fetchAllPositionparent, deletePositionparent } from "../redux/toolkit/features/organization/positionparent";
import { fetchAllPositionperson, deletePositionperson } from "../redux/toolkit/features/organization/positionperson";
import { fetchAllPersonsview } from "../redux/toolkit/features/organization/personview";
import { fetchAllRoleview } from "../redux/toolkit/features/organization/roleview";

import { fetchAllOrganization } from "../redux/toolkit/features/organization/organization";

export const peopledatasets = createContext();

function Organization() {
  const growth = Object.values(useSelector((state) => state.roadmapgrowth.entities));
  const cagr = Object.values(useSelector((state) => state.roadmapcagr.entities));
  const rows = Object.values(useSelector((state) => state.roadmaprows.entities)).sort((a, b) => a.order - b.order);
  const cellvalues = Object.values(useSelector((state) => state.roadmapcells.entities));
  const competencies = Object.values(useSelector((state) => state.competencies.entities)).sort((a, b) => (a.competency.localeCompare(b.competency)));
  const rolecompetencies = Object.values(useSelector((state) => state.rolecompetencies.entities));
  const roleview = Object.values(useSelector((state) => state.roleview.entities));
  const personview = Object.values(useSelector((state) => state.personsview.entities));
  const personyear = Object.values(useSelector((state) => state.organization.entities));

  //Mount & unmount Modals
  const showModalDelete = useSelector((state) => state.deleteConfirmModal.isOpen);
  const isRoleLinkPersonModalOpen = useSelector((state) => state.organization_state.isRoleLinkPersonModalOpen);
  const isRoleLinkReportingModalOpen = useSelector((state) => state.organization_state.isRoleLinkReportingModalOpen);
  const isTalentSelectPersonModalOpen = useSelector((state) => state.organization_state.isTalentSelectPersonModalOpen);
  const isRoleModalOpen = useSelector((state) => state.organization_state.isRoleModalOpen);
  const isCompetencyScoresModalOpen = useSelector((state) => state.organization_state.isCompetencyScoresModalOpen);
  const isOverallScoresModalOpen = useSelector((state) => state.organization_state.isOverallScoresModalOpen);
  const isRoleLinkCompetencyModalOpen = useSelector((state) => state.organization_state.isRoleLinkCompetencyModalOpen)
  const showModalCompetencies = useSelector((state) => state.competencies.isOpen);
  const isProductivityModalOpen = useSelector((state) => state.organization_state.isProductivityModalOpen);
  const isProductivityLinksModalOpen = useSelector((state) => state.organization_state.isProductivityLinksModalOpen);
  const showModalPeople = useSelector((state) => state.persons.isOpen);

  const dispatch = useDispatch();
  dispatch(setMenu('organization'));
  const [selectedTab, setSelectedTab] = useState("Organizational Design");
  const [tenants, setTenants] = useState(null);
  const [metrics, setMetrics] = useState(null);
  const [roleperson, setRolePerson] = useState(null);
  const [sankey, setSankey] = useState(null);
  const [orgroles, setOrgRoles]= useState(null);
  const [isTalentModalOpen, setIsTalentModalOpen] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const backgroundcolor = useSelector((state) => state.colors.backgroundcolor)
  const recordtype = useSelector((state) => state.deleteConfirmModal.recordtype);
  const recordkey = useSelector((state) => state.deleteConfirmModal.recordpk);    

  async function fetchTalent() {
    const roleperson = await talentrolepersonService.getTalentRolePerson();
    setRolePerson(roleperson);  
    const sankey = await talentsankeyService.getSankey();
    setSankey(sankey);  
    const metrics = await metricsviewService.getAll();
    setMetrics(metrics);  
    dispatch(fetchAllRows());
    dispatch(fetchAllCells());
    dispatch(fetchAllGrowth());
    dispatch(fetchAllCagr());
    dispatch(fetchAllCompetencies());
    dispatch(fetchAllRoleCompetencies());
    dispatch(fetchAllRoleview());
    dispatch(fetchAllPersonsview());
    dispatch(fetchAllOrganization());
    const tenants = await tenantsService.getAll();
    setTenants(tenants);  
    //const orgroles = await organizationRolesService.getAll();
    //setOrgRoles(orgroles);
  }

  useEffect(() => {
    async function fetchData() {
      setIsDataLoading(true);
        await fetchTalent();
      setIsDataLoading(false);
    }

    fetchData();
  }, []);

  if (!personyear) {
    return null
  }

  var minYear = (personyear[0]?personyear[0].start_year:new Date().getFullYear())
  var maxYear = (personyear[0]?personyear[0].end_year:minYear+4) 

  async function onConfirmDelete(result) {
    if (result) {
        
      if (recordtype === 'competency') {
          await dispatch(deleteCompetencies(recordkey))
          .unwrap()
            dispatch(fetchAllCompetencies());
            dispatch(closeDeleteModal());
      }

      if (recordtype === 'people') {
        await dispatch(deletePersons(recordkey))
        .unwrap()
          dispatch(closeDeleteModal());
          dispatch(fetchAllPersons());
      }

      if (recordtype === 'productivity') {
        await dispatch(deleteProductivity(recordkey))
        .unwrap()
        dispatch(fetchAllProductivity());
        dispatch(closeDeleteModal());
      }

      if (recordtype === 'role') {
        await dispatch(deleteRoles(recordkey))
        .unwrap()
        dispatch(fetchAllRoles());
        dispatch(closeDeleteModal());
      }

      if (recordtype === 'roleperson') {
        await dispatch(deleteRolePerson(recordkey))
        .unwrap()
        dispatch(fetchAllRolePerson());
        dispatch(closeDeleteModal());
      }

      if (recordtype === 'rolecompetency') {
        await dispatch(deleteRoleCompetencies(recordkey))
        .unwrap()
        dispatch(fetchAllRoleCompetencies());
        dispatch(closeDeleteModal());
      }

      if (recordtype === 'rolereporting') {
        await dispatch(deletePositionparent(recordkey))
        .unwrap()
        dispatch(fetchAllPositionparent());
        dispatch(fetchAllRoleview());
        dispatch(closeDeleteModal());
      }

      if (recordtype === 'positionperson') {
        await dispatch(deletePositionperson(recordkey))
        .unwrap()
        dispatch(fetchAllPositionperson());
        dispatch(fetchAllPersonsview());
        dispatch(closeDeleteModal());
      }
    }
}

  // async function onDataChanged(title, status, person_id, parent_id, compensationbase, compensationsti, compensationlti, compensationbenefits, key) {
  //   if (key) {
  //     await personYearService.patch(key, {title: title, status: status, person: 1, parent: 1, businessunit: 1, compensationbase: compensationbase, compensationsti: compensationsti, compensationlti: compensationlti, compensationbenefits: compensationbenefits});
  // } else {
  //      await personYearService.post({title: title, status: status, person: 1, parent: 1, businessunit: 1, compensationbase: compensationbase, compensationsti: compensationsti, compensationlti: compensationlti, compensationbenefits: compensationbenefits});
  // }
  //   await fetchTalent();
  // }


  // function toggleTalentModal(open) {
  //   setIsTalentModalOpen(open)
  // }


  if (!roleview || !personview || !tenants || !personyear || !metrics) {
    return <Spinner/>;
  }

  const startmonth = (tenants && tenants.results[0].financialyearstart?tenants.results[0].financialyearstart:1)
  const basecurrency = (tenants && tenants.results[0].basecurrency?tenants.results[0].basecurrency:'USD')


  function renderorgpage() {
    return (
      <peopledatasets.Provider value={{metrics, rows, cagr, growth, roleview,personview, competencies, rolecompetencies}}>
      <Fragment>
        <main className="has-navbar-fixed-top" style={{marginTop: "3.5em"}}>   
          <section style={{paddingLeft: "10%", paddingRight: "10%", marginTop: "2em", userSelect: "none"}}>
            <div style={{fontWeight: "600",fontFamily: "Gotham Extra",fontSize: "32px",lineHeight: "26px",paddingBottom: "28px"}}>
                <div className="pr-1">
                  PEOPLE <button className="button is-small is-pulled-right" style={{color: backgroundcolor, borderColor: backgroundcolor, borderRadius:"5px"}} onClick={() => dispatch( openRolesModal({roleId:null}))}>Add Role</button>
                </div>
                  <div key="TabHeader" className="tabs ml-0 pl-0" >
                    <ul className="pl-0 ml-0" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}>
                      <li 
                        key="OrganizationDesign" 
                        className={selectedTab === "Organizational Design" ? "is-active" : ""}
                        onClick={() => setSelectedTab("Organizational Design")}  
                        >           
                        <a className="ml-0 pl-0">ORGANIZATIONAL DESIGN</a>
                      </li>
                      |
                      <li
                        key="Metrics" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}
                        className={selectedTab === 'Metrics' ? "is-active" : ""}
                        onClick={() => setSelectedTab('Metrics')}  
                        >       
                        <a >PRODUCTIVITY</a>
                      </li>
                      |
                      <li
                        key="TalentManagement" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}
                        className={selectedTab === 'Talent Management' ? "is-active" : ""}
                        onClick={() => setSelectedTab('Talent Management')}  
                        >       
                        <a>TALENT PIPELINE</a>
                      </li>
                      |
                      <li
                        key="Roles" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}
                        className={selectedTab === 'Roles' ? "is-active" : ""}
                        onClick={() => setSelectedTab('Roles')}  
                        >       
                        <a >ROLES</a>
                      </li>
                      |
                      <li
                        key="People" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}
                        className={selectedTab === 'People' ? "is-active" : ""}
                        onClick={() => setSelectedTab('People')}  
                        >       
                        <a >PEOPLE</a>
                      </li>
                      |
                      <li
                        key="Compensation" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}
                        className={selectedTab === 'Compensation' ? "is-active" : ""}
                        onClick={() => setSelectedTab('Compensation')}  
                        >       
                        <a >COMPENSATION</a>
                      </li>
                      |
                      <li
                        key="Competencies" style={{fontFamily: "Gotham Extra",fontSize: "13px"}}
                        className={selectedTab === 'Competencies' ? "is-active" : ""}
                        onClick={() => setSelectedTab('Competencies')}  
                        >       
                        <a >COMPETENCIES</a>
                      </li>
                    </ul>
                </div>
              </div>
             
              {selectedTab === "Organizational Design"  &&
                <Fragment>
                   <OrgChart 
                      roleview={roleview} 
                      personview={personview} 
                      minYear={minYear} 
                      maxYear={maxYear}
                      startmonth={startmonth}
                      basecurrency={basecurrency}
                    />
  
                   <OrgTable 
                      roleview={roleview} 
                      personview={personview}                   
                      minYear={minYear} 
                      maxYear={maxYear}
                      startmonth={startmonth}
                    />
                </Fragment>
              }
  
              {selectedTab === "Talent Management"  &&
                <Fragment>
                  <Talent
                    //persons={persons}
                    personyear={personyear}
                    roleperson={roleperson}
                    personview={personview}
                    sankey={sankey}
                    positions={roleview}
                    //competencies={competencies}
                    //toggleTalentModal = {toggleTalentModal}
                    />
                </Fragment>
              }
  
              {selectedTab === "Metrics"  &&
                <Fragment>
                  <OrgMetrics 
                    selectedTab={selectedTab}
                    minYear={minYear}
                    maxYear={maxYear}
                    cellvalues={cellvalues}
                    />
                </Fragment>
              }
  
              {selectedTab === "Roles"  && <Roles/>}
  
              {selectedTab === "People"  && <People/>} 

              {selectedTab === "Compensation"  && <Compensation/>} 
  
              {selectedTab === "Competencies"  && <Competencies/>} 
  
            </section>
        </main>
  
        {/* <TalentModal 
          //showTalentModal={isTalentModalOpen}
          //toggleTalentModal = {toggleTalentModal}
          //toggleTalentPositionModal = {toggleTalentPositionModal}
          personyear={personyear}
        /> */}
  
        {isCompetencyScoresModalOpen && <TalentScoresModal/>}
        {isOverallScoresModalOpen && <TalentScoresOverallModal/>}
        {isRoleModalOpen && <PeopleModal/>}
        {isRoleLinkPersonModalOpen && <PeopleModalAddPeople/>}
        {isRoleLinkCompetencyModalOpen && <PeopleModalAddCompetency/>}
        {isRoleLinkReportingModalOpen && <PeopleModalAddReporting/>}
        {showModalCompetencies && <CompetenciesModal/>}
        {isProductivityModalOpen && <MetricsModal/>}
        {isProductivityLinksModalOpen && <MetricsModalLink/>}
        {showModalPeople && <PeoplesModal/>}
        {isTalentSelectPersonModalOpen && <SelectTalentPersonModal/>}
        {showModalDelete && <ConfirmDeleteBox onConfirmDelete={onConfirmDelete}/>}
   
      </Fragment>
      </peopledatasets.Provider>

    )
  }

  return (
   (isDataLoading?<Spinner/>:renderorgpage())
  )
}

export { Organization };
