import React, { useEffect, Fragment, useState, useRef  } from "react";
import { useSelector } from 'react-redux'
import {filter} from "lodash"
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Export, StackingColumnSeries, Tooltip, Highlight, WaterfallSeries, DataLabel, Zoom, Crosshair, ScatterSeries } from '@syncfusion/ej2-react-charts';


function ThemeChart(props) {
    const { basevalue, targetvalue, units, theme, themePK, ideasfiltered, decimals} = props;
    // const isIdeasModalOpen = useSelector((state) => state.innovation_state.isIdeasModalOpen) 
    const [chartid, setChartid] = useState(null);
    const [chartstatus, setChartStatus] = useState('Waterfall');
    var chartidvalue = null;
    var chartstatusvalue = 'Waterfall';
    var numberformat = "n"+decimals

    useEffect(() => {
        setChartid(chartidvalue)
        setChartStatus(chartstatusvalue)
      }, [chartidvalue])

    // useEffect(() => {
    //     ideasfiltered=filter(ideas,{theme: themePK})
    //   }, [isIdeasModalOpen])

    // useEffect(() => {
    //   }, [ideasfiltered])

    function scatterchart() {
        if (chartstatus==="Scatter") {
            let scatterarray=[];
            for (const row of ideasfiltered) {
                let scatterobj={};
                scatterobj['x']=(row.cost!==null?row.cost:0);
                scatterobj['y']=(row.impact!==null?row.impact:0);       
                scatterarray.push(scatterobj)
            }
            const marker = { width: 25, height: 25 };
            const primaryyAxis = { title: units};
            const primaryxAxis = { title: 'Cost'};
            var margin= { right: 30 };
            const tooltip={ enable: true, format: '<b>Cost: $${point.x} </b><br> <b>Impact: ${point.y} ' + units + '</b>' }
            return <ChartComponent id={'scattercharts'+themePK} primaryYAxis={primaryyAxis} useGroupingSeparator={true} primarxYAxis={primaryxAxis} title={theme} tooltip={tooltip} margin={margin} >
            <Inject services={[ScatterSeries, Legend, Tooltip, DataLabel, Category]}/>
            <SeriesCollectionDirective>
            {renderscatterchart(marker, scatterarray)}
            </SeriesCollectionDirective>
        </ChartComponent>;
        }
    }

    function renderscatterchart(marker, scatterarray) {
        var cells = [];
        for (let i = 0; i < ideasfiltered.length; i++) {
            cells.push(<SeriesDirective dataSource={[scatterarray[i]]} xName='x' yName='y' name={ideasfiltered[i].title} type='Scatter' marker={marker}/>);
        }
        return cells;
    }


    function waterfallchart() {
        if (chartstatus==="Waterfall") {
            let waterfallarray=[];
            let counter = basevalue;
            let yaxismax = basevalue;            
            waterfallarray.push({x: "Current Base", y: basevalue, color:'CornflowerBlue'})
            for (const row of ideasfiltered) {
                let waterfallobj={};
                waterfallobj['x']=(row.title!==null?row.title:"Idea "+row.impact);
                waterfallobj['y']=(row.impact!==null?row.impact:0);
                if (yaxismax + (row.impact!==null?row.impact:0) > yaxismax) {
                    yaxismax = yaxismax + (row.impact!==null?row.impact:0)
                }
                waterfallarray.push(waterfallobj)
                counter=counter + (row.impact!==null?row.impact:0)
            }

            if (targetvalue > yaxismax) {
                yaxismax = targetvalue;
            }

            waterfallarray.push({x: "Gap",y: (targetvalue-counter),color:'orange' })
            waterfallarray.push({x: "Target",y: targetvalue,color:'CornflowerBlue' })

            const primaryxAxis = { valueType: 'Category', majorGridLines: { width: 0 }, plotOffset: 20 };
            const primaryyAxis = { title: units, minimum: 0, maximum: yaxismax, format: numberformat};
            const marker = { dataLabel: { visible: true, format: numberformat, font: { color: '#ffffff' } }};
            const connector = { color: '#000000', width: 1};
            const tooltip = { enable: true, shared: false };
            const legendSettings = { visible: false };
            const indexsum = ideasfiltered.length+2
            //useGroupingSeparator: true
            //chartInstance.exportModule.export('PNG', 'sample');
            return (
                <ChartComponent id={'waterfallcharts'+themePK} primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} useGroupingSeparator={true} tooltip={tooltip} legendSettings={legendSettings} title={theme}>
                    <Inject services={[WaterfallSeries, Category, Tooltip, Zoom, Export, Crosshair, Legend, DataLabel]}/>
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={waterfallarray} xName ='x' yName='y' name={units} type='Waterfall' pointColorMapping="color" sumIndexes={[indexsum]} marker={marker} connector={connector} columnWidth={0.9} negativeFillColor='#e56590'>
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>)
        }
    };


    function displayChart(event) {
        setChartStatus(event.target.value);
    }

    function chartdisplay() {
            return (
            <Fragment>
                <div className="pl-4 pt-5">
                    <label style={{fontSize:"14px", fontWeight: 600}}>Displaying Chart: </label>
                    <select 
                        className="mb-4 ml-2 pl-2 pr-3"  
                        onChange={(event) => displayChart(event)}
                    >
                    <option id={2} key={1} value={'Waterfall'}>{'Waterfall Chart: Impact'}</option>
                    <option id={1} key={2} value={'Scatter'}>{'Scatter Chart: Cost Vs Impact'}</option>

                    </select>
                </div>
                </Fragment>
            )
    }

    return (
        <Fragment>
            <div className="pb-3" style={{backgroundColor: "white"}}>
            {chartdisplay()} 
            <div className="ml-4 mr-4 mb-4" style={{border:"1px Gainsboro solid"}}>
            {waterfallchart()}  
            {scatterchart()}  

            </div>
            </div>
        </Fragment>
    )
}

export { ThemeChart };
