import React, { Fragment, useState, useEffect } from "react";
import "./common/css/aside.css";
import "./common/css/masterstyles.css";
import "./common/css/sprints.css";
import { useDispatch, useSelector } from "react-redux";
import { setMenu } from "../redux/toolkit/features/menu";
import { tenantsService } from "../services/TenantsService";
import { useHistory } from "react-router-dom";
//import {menuModalActions } from "../redux/actions/menuModal";
import tenants, { uploadLogo, fetchAllTenants, logoUrl } from "./../redux/toolkit/features/tenants"
import ConfirmLogout from "./../components/common/ConfirmLogout";
import ExportPowerpointModal from "./../components/settings/ExportPowerpointModal";
import ViewImageModal from "./../components/settings/ViewImageModal";
import {setColor} from "./../redux/toolkit/features/colors";
import { openHelpModal } from "../redux/toolkit/features/tenants/help";
import { Help } from "./Help";
import {excelService} from "../services/ExcelService";
import { openExportPowerpointModal } from "../redux/toolkit/features/settings_state";
//import {powerpointService} from "../services/PowerpointService";



function Menu() {
  const dispatch = useDispatch();
  const history = useHistory();
  const tenant = Object.values(useSelector((state) => state.tenants.entities))
  const isHelpModalOpen = useSelector((state) => state.help.isHelpModalOpen);
  const isExportPowerpointModal = useSelector((state) => state.settings_state.isExportPowerpointModal);
  const isViewImageModal = useSelector((state) => state.settings_state.isViewImageModal);
  const activeMenu = useSelector((state) => state.menu.menu);
  const [logoState, setLogoState] = useState(true)
  const [showLogoutModal, setShowLogoutModal] = useState(null)
  var excelfiledata=null;
  var powerpointfiledata=null;
  //const ipath = tenantsService.logoUrl(currentTenant)
   var currentTenant=tenant[0]?tenant[0]:null

   console.log("Munu View", isViewImageModal)
  useEffect(() => {
    dispatch(fetchAllTenants());
  },[]);


  useEffect(() => {
    document.addEventListener('keydown', (e) => {  
        if (e.altKey && e.code === 'KeyH') {
            dispatch(openHelpModal());
        }  
    })
  })


  if (!currentTenant || !activeMenu) {
    return null;
  }
   dispatch(setColor({backgroundcolor:currentTenant.backgroundcolor,textcolor:currentTenant.textcolor}))

  function onClickChangeMenu(name) {
    dispatch(setMenu(name));
    history.push(`/${name}`);
  }

  // function handleOnClickMenu(event) {
  //   dispatch(menuModalActions.openModal());
  // }

  function toggleLogoutModal(open) {
    setShowLogoutModal(open)
  }

  // function logout() {
  //   if (window.confirm("Logout?")) {
  //     dispatch(loginActions.logout());
  //   }
  // }

  var menucolor='rgb(45,45,45)'

  return (
    <Fragment>
      <nav className="navbar is-fixed-top has-shadow" style={{backgroundColor:'white', height: '3.25rem'}}role="navigation" aria-label="main navigation">
        <div className="navbar-brand">      
          <div className="navbar-item pl-3">
            <div className="centerimage" style={{userSelect: "none"}}>
                {currentTenant && logoState && (
                  <img
                    className="menuimage"
                    alt="Company Logo"
                    //src={dispatch(logoUrl())}
                    src={tenantsService.logoUrl(currentTenant)}
                    style={{ maxHeight: "2rem", maxWidth: "180px", width: "auto", height: "auto" }}
                    //onDoubleClick={()=> setLogoState(false)}
                  />
                )}
            </div>
                {/* {currentTenant && !logoState && (
                  <Fragment>
                  <div className="file" >
                    <label className="file-label">
                    <input 
                        className="file-input" 
                        type="file" 
                        name="image"
                        accept="image/gif, image/jpeg, image/png"
                        onChange={async (event) => onImageFileSelected(event)}
                        />
                        <span className="file-cta" style={{backgroundColor:'transparent'}}>
                            <span className="file-icon">
                                <i className="fas fa-upload"></i>
                            </span>
                            <span className="file-label"> Upload Logo </span>
                        </span>
                    </label>
                  </div>
                  </Fragment>
                )} */}

            <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          </div>
          <div className="navbar-menu">  
          <div className="navbar-end">     
              <div className="navbar-item">
                { activeMenu === "company"? <a style={{color: menucolor, caretColor:'transparent', fontWeight:'600'}}  onClick={() => onClickChangeMenu("company")}>Company</a> : <a style={{color:'black',caretColor:'transparent', fontWeight:'300'}} onClick={() => onClickChangeMenu("company")}>Company</a>}
              </div>
              <div className="navbar-item pl-2 has-text-white">
                { activeMenu === "success"? <a style={{color: menucolor,caretColor:'transparent', fontWeight:'600'}}  onClick={() => onClickChangeMenu("success")}>Success Vision</a> : <a style={{color: 'black',caretColor:'transparent', fontWeight:'300'}} onClick={() => onClickChangeMenu("success")}>Success Vision</a>}
              </div>
              <div className="navbar-item pl-2 ">
                { activeMenu === "roadmaps"? <a style={{color: menucolor,caretColor:'transparent', fontWeight:'600'}}  onClick={() => onClickChangeMenu("roadmaps")}>Roadmaps</a> : <a style={{color: 'black',caretColor:'transparent', fontWeight:'300'}} onClick={() => onClickChangeMenu("roadmaps")}>Roadmaps</a>}
              </div>
              <div className="navbar-item pl-2">
                { activeMenu === "sprints"? <a style={{color: menucolor,caretColor:'transparent', fontWeight:'600'}}  onClick={() => onClickChangeMenu("sprints")}>Sprints</a> : <a style={{color:'black',caretColor:'transparent', fontWeight:'300'}} onClick={() => onClickChangeMenu("sprints")}>Sprints</a>}
              </div>
              <div className="navbar-item pl-2">
                { activeMenu === "innovation"? <a style={{color: menucolor, caretColor:'transparent', fontWeight:'600'}}  onClick={() => onClickChangeMenu("innovation")}>Innovation</a> : <a style={{color: 'black',caretColor:'transparent', fontWeight:'300'}} onClick={() => onClickChangeMenu("innovation")}>Innovation</a>}
              </div>
              <div className="navbar-item pl-2">
                { activeMenu === "organization"? <a style={{color: menucolor, caretColor:'transparent', fontWeight:'600'}}  onClick={() => onClickChangeMenu("organization")}>People</a> : <a style={{color: 'black',caretColor:'transparent', fontWeight:'300'}} onClick={() => onClickChangeMenu("organization")}>People</a>}
              </div>
              <div className="navbar-item pl-2">
                { activeMenu === "esg"? <a style={{color: menucolor, caretColor:'transparent', fontWeight:'600'}}  onClick={() => onClickChangeMenu("esg")}>Stakeholders</a> : <a style={{color: 'black',caretColor:'transparent', fontWeight:'300'}} onClick={() => onClickChangeMenu("esg")}>Stakeholders</a>}
              </div>
              <div className="navbar-item pl-2">
                { activeMenu === "investments"? <a style={{color: menucolor, caretColor:'transparent', fontWeight:'600'}}  onClick={() => onClickChangeMenu("investments")}>Investments</a> : <a style={{color: 'black',caretColor:'transparent', fontWeight:'300'}} onClick={() => onClickChangeMenu("investments")}>Investments</a>}
              </div>

            <div className="navbar-item has-dropdown is-hoverable pr-3">
              <a className="navbar-item" style={{ fontSize: "1em"}}>
                <i className="fa fa-user" style={{color: 'rgb(90,90,90'}}/>
              </a>
              <div className="navbar-dropdown is-right">
          <a className="navbar-item" onClick={() => onClickChangeMenu("profile")}>
            My Profile
          </a>
          <a className="navbar-item is-selected" onClick={() => onClickChangeMenu("settings")}>
            Settings
          </a>
          <a className="navbar-item is-selected" onClick={() => onClickChangeMenu("users")}>
            Users
          </a>
          <hr className="navbar-divider"/>
          <a className="navbar-item" onClick={() => {
              dispatch(openHelpModal());
            }}>
            Help
          </a>
          <a className="navbar-item" onClick={() => onClickChangeMenu("support")}>
            Contact
          </a>
          <hr className="navbar-divider"/>
          <div className="dropdown-item">
        <p style={{fontWeight:"500", fontSize:"smaller"}}>
          REPORTS
        </p>
        </div>
          <a className="navbar-item" onClick={async () => {
            await excelService.getExcel();
            }}>
            Export to Excel
          </a>
          <a className="navbar-item" onClick={async () => {
              dispatch(openExportPowerpointModal())
              // await powerpointService.getPowerpoint();   
            }}>
            Export to PowerPoint
          </a>
          <hr className="navbar-divider"/>
          <a className="navbar-item" onClick={() => toggleLogoutModal(true)}>
            Logout
          </a>
          </div>
        </div>
        </div>
        </div>  
      </nav>

      <ConfirmLogout
        showLogoutModal={showLogoutModal}
        toggleModal={toggleLogoutModal} 
      />

      {isExportPowerpointModal &&
      <ExportPowerpointModal/>}

      {isViewImageModal &&
      <ViewImageModal/>}

      {isHelpModalOpen && <Help/>}

    </Fragment>



  );
}

export { Menu };
