import React, { Fragment, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'
import {filter} from "lodash"
import { RenderBaseline} from "./SuccessRenderBaseline";
import { RenderGantt} from "./SuccessRenderGantt";
import { fetchAllRoadmap } from "./../../redux/toolkit/features/roadmaps/roadmap";
import { fetchAllSections } from "./../../redux/toolkit/features/roadmapsection";
import { fetchAllRows } from "./../../redux/toolkit/features/roadmaprows";
import { fetchAllCells } from "./../../redux/toolkit/features/roadmapcells";
import { fetchAllBaseline } from "../../redux/toolkit/features/success/baseline";
import { fetchAllBaselinedelta } from "../../redux/toolkit/features/success/baselinedelta";
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import './numericinput.css';



function SuccessRoadmapActions(props) {
    const { linkedrows, success_id, onCellDataChanged } = props; 
    const roadmap = Object.values(useSelector((state) => state.roadmap.entities));
    const sections = Object.values(useSelector((state) => state.sections.entities));
    const rows = Object.values(useSelector((state) => state.roadmaprows.entities));
    const cellvalues = Object.values(useSelector((state) => state.roadmapcells.entities));
    const baselinevalues = Object.values(useSelector((state) => state.baseline.entities));
    const baselinevaluesstatus = useSelector((state) => state.baseline.status);
    const baselinedelta = Object.values(useSelector((state) => state.baselinedelta.entities));
    const linkedrowsfiltered = filter(linkedrows, {success: success_id})
    
    const dispatch = useDispatch();

    async function fetchRoadmaps() {
        dispatch(fetchAllRoadmap());
        dispatch(fetchAllSections());
        dispatch(fetchAllRows());
        dispatch(fetchAllCells());
        dispatch(fetchAllBaseline());
        dispatch(fetchAllBaselinedelta());
      }
    
      useEffect(() => {
        fetchRoadmaps();   
      }, []);

      useEffect(() => {
        if (baselinevaluesstatus==="idle") {
            dispatch(fetchAllBaselinedelta());
        }
      }, [baselinevaluesstatus]);


      if (!roadmap) {
        return null;
      }
      const minYear = (roadmap[0]?roadmap[0].min_year:0)
      const maxYear = (roadmap[0]?roadmap[0].max_year:0)


      function renderYearHeaders() {
        const headers = [];
        for (let year = minYear; year <= maxYear; year++) {
            if (year <  new Date().getFullYear()) {
            headers.push(<th className="has-text-centered" key={year}>{year} Actual</th>);
            } else {
                headers.push(<th className={'has-text-centered'} key={year}>{year}</th>);
            }
        }
        headers.push(<td></td>)
        headers.push(<td></td>)
        return headers;
    }


    function renderRoadmap(rowid, type, decimalplaces) {
        const roadmap = [];
        let format="n0"
        switch(type) {
            case 'Number':
                format="n"+decimalplaces.toString()
                break;
            case 'Percent':
                format="p"+decimalplaces.toString()
                break;
        }
        
        for (let year = minYear; year <= maxYear; year++) {
            let celldata = filter(cellvalues,{year: year, row_id: rowid.row_id})
            let baselinedata = filter(baselinedelta,{year: year, row_id: rowid.row_id})
            if (baselinedata[0]) {
                let alertstatus=(baselinedata[0]? baselinedata[0].alertstatus:'None')
                var alertcolor='inherit';
                if (alertstatus==='Severe') {
                    alertcolor='Red'
                } 
                if (alertstatus==='Moderate') {
                    alertcolor='#fc7b03'
                }
            }

            {celldata.length!==0 &&
                roadmap.push( <td className="has-text-centered"><NumericTextBoxComponent className="nboxoff mb-0 has-text-centered" key={rowid} format={format} showSpinButton={false} style={{border: 'none', color: alertcolor}} type="text" value={celldata[0].numeric_value}/></td>)
            }

            {celldata.length===0 &&
            roadmap.push( <td className="has-text-centered"><input style={{textAlign:'center', color:alertcolor, border: 'none', background: 'transparent', userSelect:"none", pointerEvents: "none"}} value={null} /></td>)
            }
        }
        roadmap.push(<td></td>)
        roadmap.push(<td></td>)
        return roadmap;
    }





    function renderBaselineDelta(rowid, type, decimalplaces) {
        const baseline = [];
        let format="n0"
        switch(type) {
            case 'Number':
                format="n"+decimalplaces.toString()
                break;
            case 'Percent':
                format="p"+decimalplaces.toString()
                break;
        }
    
        for (let year = minYear; year <= maxYear; year++) {
            let baselinedata = filter(baselinedelta,{year: year, row_id: rowid.row_id})
            if (baselinedata[0]) {
                let alertstatus=(baselinedata[0]? baselinedata[0].alertstatus:'None')
                var alertcolor='inherit';
                if (alertstatus==='Severe') {
                    alertcolor='Red'
                } 
                if (alertstatus==='Moderate') {
                    alertcolor='#fc7b03'
                }
                baseline.push( <td className="has-text-centered"><NumericTextBoxComponent className="nboxoff mb-0 has-text-centered" key={rowid} format={format} showSpinButton={false} style={{border: 'none', color: alertcolor}} type="text" value={baselinedata[0].difference}/></td>)
            } else {
                baseline.push( <td className="has-text-centered" style={{color: "transparent"}}>{'No Value'}</td>)
            }
        }
        baseline.push(<td></td>)
        baseline.push(<td></td>)
        return baseline;
    }

    function renderBaselinePercent(rowid) {
        const baseline = [];
        let format="p2"
        for (let year = minYear; year <= maxYear; year++) {
            let baselinedata = filter(baselinedelta,{year: year, row_id: rowid.row_id})
            if (baselinedata[0]) {
                let alertstatus=(baselinedata[0]? baselinedata[0].alertstatus:'None')
                var alertcolor='inherit';
                if (alertstatus==='Severe') {
                    alertcolor='Red'
                } 
                if (alertstatus==='Moderate') {
                    alertcolor='#fc7b03'
                }
                baseline.push( <td className="has-text-centered"><NumericTextBoxComponent className="nboxoff mb-0 has-text-centered" key={rowid} format={format} showSpinButton={false} style={{border: 'none', color: alertcolor}} type="text" value={baselinedata[0].growth/100}/></td>)
        } else {
            baseline.push( <td style={{textAlign: "center", color: "transparent"}}>{'No Value'}</td>)
        }
        }
        baseline.push(<td></td>)
        baseline.push(<td></td>)
        return baseline;
    }

    function renderTable(rowid) {
        let rowname=[]
        rowname=filter( rows, {pk: rowid.row_id})
        console.log(" Type Error", rowid, rowname, rows)
        if(rows.length===0) {
            return null
        }
        return (
            <Fragment>
                <div className="ml-3 mr-3 mb-4">
                <table className={'table is-fullwidth'}>
                    <tbody>
                        <tr>
                            <th>{rowname[0]?rowname[0].name:""}</th>
                            {renderYearHeaders()}
                        </tr>
                        <tr>
                            <th className="pt-3" style={{fontFamily: "Sans-serif", fontSize: "13px"}}>Baseline</th> 
                            {rowname[0] &&
                            <RenderBaseline 
                                rowid={rowid}
                                type={rowname[0].type}
                                decimalplaces={rowname[0].decimalplaces}
                                baselinealertmoderate={rowname[0].baselinealertmoderate}
                                baselinealertsevere={rowname[0].baselinealertsevere}
                                baselinevalues={baselinevalues}
                                baselinedelta={baselinedelta}
                                minYear={minYear}
                                maxYear={maxYear}
                                />}
                        </tr>
                        <tr>
                            <th className="pt-3" style={{fontFamily: "Sans-serif", fontSize: "13px"}}>Roadmap</th>
                            {renderRoadmap(rowid, rowname[0].type, rowname[0].decimalplaces)}
                        </tr>
                        <tr>
                            <td className="pt-3" style={{fontFamily: "Sans-serif", fontSize: "13px"}}>Delta Δ</td>
                            {renderBaselineDelta(rowid, rowname[0].type, rowname[0].decimalplaces)}
                        </tr>
                        <tr >
                            <td className="pt-3" style={{fontFamily: "Sans-serif", fontSize: "13px"}}>Delta %</td>
                            {renderBaselinePercent(rowid)}
                        </tr>
                    </tbody>
                </table>
                </div>
            </Fragment>
        )
    }

    function renderSection(sectionid, sectionlist) {
            let sectiondata=[]
            sectiondata=filter( sections, {pk: sectionid})
            let sectionlinks=filter( linkedrowsfiltered, {section_id: sectionid})
            let rowdata=[]
            rowdata=filter( linkedrowsfiltered, {section_id: sectionid, linktype:'row'})

            if (sectiondata.length !== 0) {
            return (
                <Fragment>
                    <section className="mb-5 pb-3 mb-6" style={{backgroundColor: "rgb(242,242,242)"}} >
                    <div className="pl-4 pr-4 pt-5 pb-0" style={{fontFamily: 'Gotham Extra', fontWeight: 700, fontSize: '22px', lineHeight: '0.9em'}}>
                    {sectiondata[0].name}
                    </div>
                    <div  className="p-4" style={{fontFamily: 'Gotham Extra', fontSize: '15px', lineHeight: '25px'}}>
                    {sectiondata[0].narrative}
                    </div>

                    {sectionlinks.map(link => (
                        <Fragment>
                            {link.linktype==='row' &&
                                rowdata.map(rowid => (
                                    renderTable(rowid)
                                ))
                            }
                        
                            {link.linktype==='gantt' &&
                                <RenderGantt sectionid={sectionid} />
                            }
                        </Fragment>
                    ))}

                    </section>
                </Fragment>
            )
            }
    }


    function renderRoadmaps() {
        var sectionlist=[];
        if (linkedrowsfiltered.length !== 0) {
            sectionlist = [...new Set( linkedrowsfiltered.map(obj => obj.section_id)) ];
            sectionlist = sectionlist.sort(); 
            return (
                <Fragment>
                    { sectionlist && sectionlist.map(sectionid => 
                        renderSection(sectionid, sectionlist)
                    )}
                </Fragment>
            )
        } else {
            return <p className="pl-2"><b>No Linked Roadmaps Found</b></p>
        }
    }

return (
     renderRoadmaps()
)
}
export { SuccessRoadmapActions };